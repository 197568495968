import React from "react";
import { Provider } from "react-redux";

import { store } from "./src/redux/store";

const WrapWithProvider = ({ element }) => {
    return <Provider store={store}>{element}</Provider>;
};

export default WrapWithProvider;
