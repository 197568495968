import { createAppSlice } from "./createAppSlice";

const initialState = {
    show: false,
    downloadPdfUrl: "",
    source: "",
};

export const businessModalSlice = createAppSlice({
    name: "businessModal",
    initialState,
    reducers: {
        closeModal: state => {
            state = initialState;
            return state;
        },
        openModal: (state, action) => {
            state = action.payload;
            state.show = true;
            return state;
        },
    },
    selectors: {
        selectModalData: state => state,
    },
});

export const { closeModal, openModal } = businessModalSlice.actions;

export const { selectModalData } = businessModalSlice.selectors;
