/**
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import { createRoot } from "react-dom/client";

import WrapWithProvider from "./wrap-with-provider";

// Import global stylesheet
import "./src/styles/global.scss";

export function replaceHydrateFunction() {
    return (element, container) => {
        const root = createRoot(container);
        root.render(element);
    };
}

export const wrapRootElement = WrapWithProvider;
